<script lang="ts" setup>
import { __subscription } from "~/stores/subscription.store";

import type { MenuCategoryProps } from "~/types/menus";
import { Sub } from "~/types/subscriptions";

import { hasAuthorization, RoleRules, toValue } from "#imports";
import type { SubDrive } from "~/classes/subscriptions/SubscriptionDrive";
import type { SubDrivePro } from "~/classes/subscriptions/SubscriptionDrivePro";
import type { SubObjectStorage } from "~/classes/subscriptions/SubscriptionObjectStorage";
import { daysBetweenNow } from "~/composables/date";
import { useObjectStorageStats } from "~/composables/subscriptions/useObjectStorageStats";
import { toBytes } from "~/composables/units";

const config = useRuntimeConfig();

const objectStorage = computed<SubObjectStorage | undefined>(() => __subscription().getSubscriptionObjectStorage());
const drive = computed<SubDrive | undefined>(() => __subscription().getSubscriptionDrive());
const drivePro = computed<SubDrivePro | undefined>(() => __subscription().getSubscriptionDrivePro());

const categoryGeneral = computed<MenuCategoryProps>(() => {
    return {
        name: "menu.general",
        icon: "cloud",
        items: [
            {
                name: "menu.general_dashboard",
                icon: "home",
                link: "/",
                visible: true
            }
        ]
    };
});

const categoryPartners = computed<MenuCategoryProps>(() => {
    const cat: MenuCategoryProps = {
        name: "menu.partners",
        icon: "rocket",
        items: []
    };
    if (__subscription().has(Sub.Type.Partner)) {
        cat.items.push({
            name: "menu.partners_clients",
            icon: "community",
            link: "/partners",
            visible: true
        });
    }

    return cat;
});

function addObjectStorageTrial (cat: MenuCategoryProps): void {
    const s3Trial = objectStorage.value ? objectStorage.value.getTrialInformations() : null;
    if (objectStorage.value.isTrial && s3Trial) {
        const daysLeft = daysBetweenNow(s3Trial.trialEnd);

        const used = computed<number>(() => unref(objectStorage.value!.spaceDisposed) ?? 0);
        const total = computed<number>(() => toBytes(unref(objectStorage.value!.spaceTotal), "To"));

        cat.trial = {
            left: daysLeft,
            used: used.value,
            total: total.value,
            link: "/user/subscriptions",
            danger: daysLeft <= 2
        };
    }
}

function addObjectStorageIdentifier (cat: MenuCategoryProps): void {
    if (hasAuthorization(RoleRules.ObjectStorage_Identifier)) {
        cat.items.push({
            name: "menu.object_storage_identifiers",
            icon: "key-alt-plus",
            link: "/identifiers",
            visible: true
        });
    }
}

function addObjectStorageBucket (cat: MenuCategoryProps): void {
    if (hasAuthorization(RoleRules.ObjectStorage_Bucket)) {
        cat.items.push({
            name: "menu.object_storage_buckets",
            icon: "bitbucket",
            link: "/buckets",
            visible: true
        });
    }
}

function addSuperset (cat: MenuCategoryProps): void {
    if (config.public.SUPERSET_URL.length > 0) {
        cat.items.push({
            name: "menu.object_storage_usage",
            icon: "graph-up",
            link: "/object-storage/statistiques",
            visible: true
        });
    }
}

const categoryS3 = computed<MenuCategoryProps>(() => {
    const cat: MenuCategoryProps = {
        name: "menu.object_storage",
        icon: "substract",
        items: []
    };

    if (objectStorage.value) {
        callOnce(() => void objectStorage.value!.fetch());

        addObjectStorageTrial(cat);

        useObjectStorageStats(objectStorage.value!);

        addObjectStorageIdentifier(cat);

        addObjectStorageBucket(cat);

        // if (hasAuthorization(RoleRules.ObjectStorage_Usage)) {
        //     cat.items.push({
        //         name: 'menu.object_storage_usage',
        //         icon: 'graph-up',
        //         link: '/statistiques',
        //         visible: true
        //     })
        // }
    }

    addSuperset(cat);

    return cat;
});

const categoryNextcloud = computed<MenuCategoryProps>(() => {
    const cat: MenuCategoryProps = {
        name: "menu.nextcloud_massive",
        icon: "frame-simple",
        items: []
    };

    return cat;
});

function updateProElementLink (pros: SubDrivePro[], proElement: {
    external: boolean;
    visible: boolean;
    name: string;
    icon: string;
    link: string
}): void {
    if (pros && pros.length <= 1 && pros[0]) {
        const instance: SubDrivePro = pros[0] as SubDrivePro;
        proElement.link = unref(instance && instance.url ? instance.url : "") || "#url_pro=not-defined";
        proElement.external = true;

        if (instance && !instance.url) {
            console.error("L'url du drive pro n'est pas définie");
        }
    }
}

function addDriveProMenuItems (cat: MenuCategoryProps): void {
    if (drivePro.value && (__pbUser().getUsername ? hasAuthorization(RoleRules.DrivePro) : true)) {
        const proElement = {
            name: "menu.drives_pro",
            icon: "large-suitcase",
            link: "/drive-pros",
            visible: true,
            external: false
        };

        const pros = __subscription().getSubscriptions.filter(s => s.type === Sub.Type.DrivePro) as SubDrivePro[];

        updateProElementLink(pros, proElement);

        cat.items.push(proElement);
    }
}

function addDriveMenuItem (cat: MenuCategoryProps): void {
    if (drive.value && (__pbUser().getUsername ? hasAuthorization(RoleRules.Drive) : true)) {
        cat.items.push({
            name: "menu.drives_solo",
            icon: "shopping-bag",
            link: config.public.URL_CLOUD,
            visible: true,
            external: true
        });
    }
}

const categoryDrive = computed<MenuCategoryProps>(() => {
    const cat: MenuCategoryProps = {
        name: "menu.drives",
        icon: "folder",
        items: []
    };

    addDriveProMenuItems(cat);

    addDriveMenuItem(cat);

    return cat;
});

const categoryDiscovery = computed<MenuCategoryProps>(() => {
    const cat: MenuCategoryProps = {
        name: "menu.discover",
        icon: "eye-alt",
        items: []
    };

    if (__pbUser().getUsername ? !hasAuthorization(RoleRules.Discover) : false) {
        return cat;
    }

    if (!__subscription().has(Sub.Type.ObjectStorage)) {
        cat.items.push({
            name: "menu.object_storage",
            icon: "substract",
            link: "/discover/object-storage",
            visible: true
        });
    }

    if (!__subscription().has(Sub.Type.Drive)) {
        cat.items.push({
            name: "menu.drives_solo",
            icon: "shopping-bag",
            link: "/discover/drive-perso",
            visible: true
        });
    }

    if (!__subscription().has(Sub.Type.DrivePro)) {
        cat.items.push({
            name: "menu.drives_pro",
            icon: "large-suitcase",
            link: "/discover/drive-pro",
            visible: true
        });
    }

    cat.items.push({
        name: "menu.nextcloud_massive",
        icon: "frame-simple",
        link: "/discover/nextcloud-massif",
        visible: true
    });

    return cat;
});

const menuId = ref<string>("");
const menu = computed<MenuCategoryProps[]>(() => {
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    menuId.value = Math.random().toString(36).substring(7);
    const menus = [];

    menus.push(categoryGeneral);

    if (hasAuthorization(RoleRules.Partner)) {
        menus.push(categoryPartners);
    }

    if (hasAuthorization(RoleRules.ObjectStorage)) {
        menus.push(categoryS3);
    }

    menus.push(categoryDrive);
    menus.push(categoryNextcloud);
    menus.push(categoryDiscovery);

    return menus.map(c => toValue<MenuCategoryProps>(c))
        .filter(c => c.items.length > 0);
});
</script>

<template>
    <nav class="menu">
        <div v-for="category in menu" :key="`cat-${category.name}-${menuId}`" class="menu__category">
            <MenuCategory
                :icon="category.icon"
                :items="category.items"
                :name="category.name"
                :trial="category.trial ?? null"
            />
        </div>
    </nav>
</template>

<style lang="scss" scoped>
.menu {
    @apply flex flex-col gap-6 text-sm;
}
</style>
