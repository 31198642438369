// Utility function to debounce another function
function debounce<T extends (...args: any[]) => void> (func: T, wait: number): T {
    let timeout: ReturnType<typeof setTimeout> | null;
    return function (...args: Parameters<T>) {
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            func(...args);
        }, wait);
    } as T;
}

// Function to get elements with specified classes
function getElementsByClasses (classes: string[]): NodeListOf<Element>[] {
    return classes.map(classToLocate => document.querySelectorAll(classToLocate));
}

// Function to check if an element has any prefixed class
function hasPrefixedClass (element: Element, prefix: string): boolean {
    return Array.from(element.classList).some(c => c.startsWith(prefix));
}

// Function to update the class list of an element
function updateElementClasses (element: Element, prefix: string): void {
    if (hasPrefixedClass(element, prefix)) {
        return;
    }
    const classes = element.classList.value.split(" ").filter(c => !c.startsWith("p-"));
    classes.forEach(c => {
        if (c.startsWith(prefix)) {
            element.classList.remove(c);
        }
        element.classList.add(prefix + c);
    });
}

// Main function to prefix classes of icons
export function prefixClassOfIcons (): void {
    const classesToLocate = [ ".p-button-icon" ];
    const prefix = "iconoir-";

    const elementsLists = getElementsByClasses(classesToLocate);
    elementsLists.forEach(elements => {
        elements.forEach(element => {
            updateElementClasses(element, prefix);
        });
    });
}

// Debounced version of prefixClassOfIcons
const debouncedPrefixClassOfIcons = debounce(prefixClassOfIcons, 100); // Adjust the wait time as needed

// Variable to hold the MutationObserver instance
let observer: MutationObserver | null = null;

// Function to observe DOM changes and reapply class prefixing
export function observeDOMChanges (): void {
    if (observer) {
        // Observer is already in place, do nothing
        return;
    }

    observer = new MutationObserver(() => {
        debouncedPrefixClassOfIcons();
    });

    // Observe changes to the entire document
    observer.observe(document.body, {
        childList: true,
        subtree: true,
        attributes: true
    });

    // Initial call to prefix classes
    debouncedPrefixClassOfIcons();
}
