<script lang="ts" setup>
import { useMeta } from "~/composables/meta";

const meta = useMeta();
</script>

<template>
    <div class="header">
        <div class="header__infos">
            <div v-if="meta.back && meta.back !== ''" class="back">
                <NuxtLink :to="meta.back">
                    <Icon icon="arrow-left" width="16px"/>
                    {{ $t('label.back') }}
                </NuxtLink>
            </div>
            <h1 v-if="meta.title">
                {{ $t(meta.title) }}
            </h1>
            <p v-if="meta.description">
                {{ $t(meta.description) }}
            </p>
        </div>
        <div class="header__actions">
            <DebugThemeActions v-if="isOnDev()"/>
            <HeaderDropdown/>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.header {
    @apply flex justify-between;

    &__infos {
        @apply max-w-[600px];

        h1 {
            @apply text-[40px] font-nordique font-bold whitespace-nowrap;
        }
    }

    &__actions {
        @apply select-none flex gap-4 items-center;
    }

    .back a {
        @apply flex items-center gap-2 text-md font-bold h-7;
    }
}
</style>
